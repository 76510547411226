import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c1f3b61"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lang" }
const _hoisted_2 = { class: "lang__menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_button = _resolveComponent("my-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_my_button, { class: "lang__active" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.languages[0].language), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableLocales, (availableLocale) => {
        return (_openBlock(), _createBlock(_component_my_button, {
          key: availableLocale,
          onClick: ($event: any) => (_ctx.changeLocale(availableLocale)),
          class: "lang__button"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(availableLocale), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ])
  ]))
}