import VueI18n from 'vue-i18n';
import ru from "./locales/ru.json"
import en from "./locales/en.json"
import { createI18n } from 'vue-i18n'

type MessageSchema = typeof en;

const i18n = createI18n<[MessageSchema], 'en' | 'ru'>({
    locale: "en",
    fallbackLocale: "en",
    globalInjection: true,
    messages: {en, ru},
    legacy: false,
})

// const translate = (key: string) => {
//     if (!key) {
//       return '';
//     }
//     return i18n.t(key);
// };
  
export { i18n };

