import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4619403a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "skills" }
const _hoisted_2 = { class: "h1__wrapper" }
const _hoisted_3 = { class: "h1" }
const _hoisted_4 = { class: "skills__menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skills_block = _resolveComponent("skills-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("mySkills.h1")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (contentBlock) => {
        return (_openBlock(), _createBlock(_component_skills_block, {
          key: contentBlock.block_name,
          content: contentBlock
        }, null, 8, ["content"]))
      }), 128))
    ])
  ]))
}