import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b7b9a4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "expirience" }
const _hoisted_3 = {
  ref: "canvas",
  class: "expirience__canvas"
}
const _hoisted_4 = { class: "hover" }
const _hoisted_5 = { class: "hover__name" }
const _hoisted_6 = { class: "hover__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("canvas", _hoisted_3, null, 512),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t("mainPage.hoverName__" + _ctx.hoverName)), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("mainPage.hoverDescription__" + _ctx.hoverName)), 1)
      ])
    ])
  ]))
}