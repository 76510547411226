<template>
    <div class="main__block sites-block">

        <h2 class="sites-block__header">{{ $t(content.name) }}</h2>

        <div class="sites-block__description-wrapper">
            <p class="sites-block__description">{{ $t(content.description) }}</p>
        </div>

        <div class="sites-block__href-wrapper">
            <template v-for="href in content.hrefs" :key="href.link">
                <a v-if="href.isArchive" class="sites-block__href webArchive" :href="href.link">{{ $t('myworks.web__archive') }}</a>
                <a v-else class="sites-block__href webArchive" :href="href.link">{{ href.link }}</a>
            </template>
            
        </div>

        <div class="sites-block__img-wrapper">
            <template v-if="content.imgs.length > 1">
                <div :class="content.id + '__swiperJS'" class="swiperJS sites-block__slider slider">

                    <div class="swiper-wrapper">
                        <div v-for="img in content.imgs" :key="img.img_src" class="swiper-slide slider__slide">
                            <img class="sites-block__img" :src="require(`@/assets/img/works/${img.img_src}`)" :alt="img.img_alt">
                        </div>
                    </div>
                    
                    
                </div>
                <div :class="content.id + '__paginationJS'" class="swiper-pagination"></div>
                <div :class="content.id + '__swiper-button-prevJS'" class="swiper-button-prev"></div>
                <div :class="content.id + '__swiper-button-nextJS'" class="swiper-button-next"></div>
            </template>


            <img v-else class="sites-block__img" :src="require(`@/assets/img/works/${content.imgs[0].img_src}`)" :alt="content.imgs[0].img_alt">

        </div>
    </div>



</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";
    import type { PropType } from 'vue';
    import Swiper from 'swiper';
    import { Navigation, Pagination } from 'swiper/modules';
    // import Swiper and modules styles
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';

    interface MyProps {
        id: string,
        name: string,
        description: string,
        hrefs: [],
        imgs: [],
    }
    
    export default defineComponent({
        name: "work-block",
        data() {
            return {
                isActive: "hi",
                nameOfWork: this.content,
            }
        },
        props: {
            content: {
                type: Object as PropType<MyProps>,
                required: true,
            }
        },
        mounted() {
            const swiper = new Swiper("." + this.content.id + '__swiperJS', {

            modules: [Navigation, Pagination],

            spaceBetween: 30,
            direction: 'horizontal',
            loop: true,

            delay: 2500,

            pagination: {
                el: "." + this.content.id + '__paginationJS',
                clickable: true,
            },

            navigation: {
                nextEl: "." + this.content.id + '__swiper-button-nextJS',
                prevEl: "." + this.content.id + '__swiper-button-prevJS',
            },
            });
        },
    })
</script>

<style scoped lang="scss">
    @import "@/scss/contentBlocks/work.scss"

</style>