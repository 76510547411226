<template>
    <button class="btn">
        <slot>

        </slot>
    </button>
</template>

<script lang="ts">
    export default {
        name: "my-button",
    }
</script>

<style scoped lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');
    .btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(60, 60, 60);
        background-clip: padding-box;
        border: 3px solid transparent;
        border-radius: 30px;
        font-family: "Rubik";
        font-size: 14px;
        font-weight: 400;
        padding: 3px 10px;
        cursor: pointer;
        color: rgb(230, 230, 230);

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: -1;
            margin: -1px;
            padding: 1px;
            border-radius: inherit;
            background: linear-gradient(to right bottom, #55ffaa, rgba(215, 64, 190));
        }

        &:hover {
            transition: all 0.3s ease-in-out;
            background-color: transparent;
            color: #ffffff;
        }
    }

</style>