import { createApp } from 'vue'
import App from './App.vue'
import UIs from "@/components/UI"
import contentBlocks from "@/components/contentBlocks"
import router from './router'
import { store } from './store'

import { i18n } from "@/i18n"
// import VueI18n from 'vue-i18n'

const app = createApp(App);

UIs.forEach(component => {
    app.component(component.name, component);
})

contentBlocks.forEach(component => {
    app.component(component.name, component);
})

app
    .use(router)
    .use(i18n)
    .use(store)
    .mount("#app")