import expirience from "../expirience";
import * as THREE from "three";
import room from "./room"
import enviroment from "./enviroment";

export default class world {

    expirience: any;
    sizes: any;
    scene: any;
    canvas: any;
    camera: any;
    renderer: any;
    room: any;
    resources: any;
    enviroment: any;

    constructor() {

        this.expirience = new expirience();
        this.sizes = this.expirience.sizes;
        this.scene = this.expirience.scene;
        this.canvas = this.expirience.canvas;
        this.camera = this.expirience.camera;
        this.resources = this.expirience.resources

        this.resources.on("ready", () => {
            this.enviroment = new enviroment();
            this.room = new room;
        });
    }

    update() {
        if(this.room) {
            this.room.update();
        }
    }

}