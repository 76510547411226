import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49f84c4d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "skills__menu-block" }
const _hoisted_2 = { class: "skills__header" }
const _hoisted_3 = { class: "skills__menu-option-wrapper" }
const _hoisted_4 = { class: "skills__menu-name" }
const _hoisted_5 = { class: "skills__menu-rating-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.content.block_name), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.skills, (skills) => {
        return (_openBlock(), _createElementBlock("div", {
          key: skills.name,
          class: "skills__menu-option"
        }, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(skills.name), 1),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skills.level, (level, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass([
                                [{'filled100': skills.level[index] == 100},
                                {'filled75': skills.level[index] == 75},
                                {'filled50': skills.level[index] == 50},
                                {'filled25': skills.level[index] == 25},], "skills__menu-rating"])
              }, null, 2))
            }), 128))
          ])
        ]))
      }), 128))
    ])
  ]))
}