import expirience from "./expirience";
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls.js";

export default class camera {

    expirience: any;
    sizes: any;
    scene: any;
    resources: any;
    canvas: any;
    frustrum: number;
    controls: any;

    perspectiveCamera: any;
    orthographicCamera: any;
    blenderCamera: any;
    activeCamera: any;

    constructor() {

        this.expirience = new expirience();
        this.sizes = this.expirience.sizes;
        this.scene = this.expirience.scene;
        this.resources = this.expirience.resources;
        this.canvas = this.expirience.canvas;
        this.frustrum = 0;

        this.crearePerspectiveCamera();
        this.createOrthographicCamera();
        this.setActiveCamera(this.perspectiveCamera);
        
        this.setOrbitControls(this.activeCamera);
    }

    crearePerspectiveCamera(): any {
        this.perspectiveCamera = new THREE.PerspectiveCamera(
            35,
            this.sizes.aspect,
            0.1,
            1000);
        // this.scene.add(this.perspectiveCamera);
        this.perspectiveCamera.position.z = 11.37;
        this.perspectiveCamera.position.x = 0.38;
        this.perspectiveCamera.position.y = 6.63;
        // const pt = new THREE.Vector3(0, 1, 2);
        // this.perspectiveCamera.lookAt(pt);
        // this.perspectiveCamera.rotation.set( 10, 10, 100 );
        this.scene.add(this.perspectiveCamera);
        // this.perspectiveCamera.target.set(0, 1, 200);
//         {x: -1.4536514895905692, y: 6.612003568441359, z: 14.172651004753181}
//          {x: -0.23347643983335464, y: 6.508430383530297, z: 14.296033522624947}
    }

    createOrthographicCamera(): any {
        this.frustrum = 5;
        this.orthographicCamera = new THREE.OrthographicCamera(
            (-this.sizes.aspect * this.frustrum) / 2,
            (this.sizes.aspect * this.frustrum) / 2,
            this.frustrum / 2,
            -this.frustrum / 2,
            -100,
            100);
        this.scene.add(this.orthographicCamera);

        const size = 10;
        const divisions = 10;

        const gridHelper = new THREE.GridHelper( size, divisions );
        // this.scene.add( gridHelper );

        const axesHelper = new THREE.AxesHelper( 5 );
        // this.scene.add( axesHelper );
    }

    loadBlenderCamera(camera: any) {
        this.blenderCamera = camera;
        this.blenderCamera.fov = 35
        this.blenderCamera.aspect  = this.sizes.aspect;
        this.blenderCamera.near  = 0.1;
        this.blenderCamera.far = 1000;
        this.setOrbitControls(this.blenderCamera);
        this.setActiveCamera(this.blenderCamera);
    }

    setActiveCamera(newActiveCamera: any) {
        this.activeCamera = newActiveCamera;
    }

    setOrbitControls(camera: any) {
        this.controls = new OrbitControls(camera, this.canvas);
        this.controls.enableDamping = false;
        this.controls.enableZoom = true;
        // this.controls.minDistance = 10;
        // this.controls.maxDistance = 14;
        // this.controls.minPolarAngle = Math.PI/3; 
        // this.controls.maxPolarAngle = Math.PI/2.5; 
        // this.controls.maxAzimuthAngle = Math.PI/16;
        // this.controls.minAzimuthAngle = -Math.PI/16;
        this.controls.target.set(0, 2, 0)
        this.controls.update();
    }

    resize(): any {
        this.perspectiveCamera.aspect = this.sizes;
        this.perspectiveCamera.updateProjectionMatrix();


        this.orthographicCamera.left = (-this.sizes.aspect * this.frustrum) / 2;
        this.orthographicCamera.right = (this.sizes.aspect * this.frustrum) / 2;
        this.orthographicCamera.top = this.frustrum / 2;
        this.orthographicCamera.bottom = -this.frustrum / 2;
    }

    update() {
        // this.perspectiveCamera.position.z = 14;
        // this.perspectiveCamera.position.x = 0;
        // this.perspectiveCamera.position.y = 8;
        this.controls.update();
    }
}