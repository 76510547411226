import expirience from "../expirience";
import * as THREE from "three";
import animations from "./animations"

export default class room {

    expirience: any;
    sizes: any;
    scene: any;
    canvas: any;
    camera: any;
    renderer: any;
    resources: any
    time: any
    room: any;
    actualRoom: any;
    mixer: any;
    animations: any;

    armatureAction: any;

    constructor() {

        this.expirience = new expirience();
        this.scene = this.expirience.scene;
        this.resources = this.expirience.resources;
        this.time = this.expirience.time;
        this.room = this.resources.items.room;
        this.actualRoom = this.room.scene;
        this.setModel();
        this.animations = new animations;

        
    }

    setModel() {

        // this.castShadows(this.actualRoom);
        const resources = this.resources

        // this.actualRoom.children.forEach(function(child: any) {
        //     if (child.name === "JS__Monitor__Trigger") {
        //         console.log(child);
        //         child.material = new THREE.MeshBasicMaterial({
        //             map: resources.items.screen,
        //         })
        //     }
        // });

        this.scene.add(this.actualRoom);
        // console.log(this.scene);
        this.setBlenderCamera();
        
        // this.actualRoom.scale.set(0.1, 0.1, 0.1);
        this.actualRoom.rotation.y = Math.PI;
    }

    castShadows(model: any) {
        model.children.forEach((child: any) => {
            child.castShadow = true;
            child.receiveShadow = true;

            if (child.children) {
                this.castShadows(child);
            }
        });
    }

    setBlenderCamera() {
        for (const child of this.actualRoom.children) {
            if (child.name === "Camera") {
                this.expirience.camera.loadBlenderCamera(child.children[0]);
            }
        }
    }

    update() {
        if(this.room) {
            this.animations.update();
        }
    }



}