import { GetterTree } from "vuex";
import { State, Lang } from "./state";

export type Getters = {
    whatLanguageIsActive(state:State): string;
}

export const getters: GetterTree<State, State> & Getters = {
    whatLanguageIsActive(state: State):string {
        let returning = "";
        state.languages.forEach(function(element: Lang) {
            if (element.isActive) {
                returning = element.name;
                return returning;
            }
            else {
                false;
            }
            
        });
        return returning;
    }
}