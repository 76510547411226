import { EventEmitter } from "events";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader.js";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader.js";
import expirience from "../expirience";
import * as THREE from "three";

type asset = {
    [key: string]: any;
}

export default class resources extends EventEmitter {

    expirience: any;
    renderer: any;
    assets: {name: string; type: string; path: string}[];
    items: asset;
    queue: number;
    loaded: number;
    loaders: {GLTFLoader: any; DRACOLoader:any};
    video: asset;
    videoTexture: asset;

    models: any;
    areaLights: any;

    constructor (assets: {name: string; type: string; path: string}[]) {
        super();
        this.expirience = new expirience();
        this.renderer = this.expirience.renderer;

        this.assets = assets;

        
        this.queue = this.assets.length;
        this.loaded = 0;

        this.loaders = {GLTFLoader: 0, DRACOLoader:0 };

        this.items = [];
        this.video = [];
        this.videoTexture = [];
        this.models = [];

        this.setLoaders();
        this.startLoading();
        // this.arrangeAnimations();
    }

    setLoaders() {
        this.loaders.GLTFLoader = new GLTFLoader();
        this.loaders.DRACOLoader = new DRACOLoader();
        this.loaders.DRACOLoader.setDecoderPath("/draco/");
        this.loaders.GLTFLoader.setDRACOLoader(this.loaders.DRACOLoader)
    }

    startLoading() {
        const models: any = [];
        const areaLights: any = [];
        for (const asset of this.assets) {
            if (asset.type === "glbModel") {
                this.loaders.GLTFLoader.load(asset.path, (file: any) => {
                    file.scene.traverse(function(this: resources, obj: any) {
                        models.push(obj);
                        if (obj.name.includes("AreaLight__ThreeJS")) {
                            areaLights.push(obj);
                        }


                        obj.frustumCulled = false; 

                        // if (obj.name.includes("Sun") && !obj.name.includes("Orientation")) {
                        //     obj.children[0].shadow.mapSize.set(1024, 1024);
                        //     obj.children[0].shadow.normalBias = 0.05;
                        //     obj.children[0].shadow.camera.far = 20;
                        // }
                    });
                    this.singlAssetLoaded(asset, file);
                });
            }
            else if (asset.type === "videoTexture") {

                this.video[asset.name] = document.createElement("video");
                this.video[asset.name].src = asset.path;
                this.video[asset.name].muted = true;
                this.video[asset.name].playsInLine = true;
                this.video[asset.name].autoplay = true;
                this.video[asset.name].loop = true;
                this.video[asset.name].play();

                this.videoTexture[asset.name] = new THREE.VideoTexture(this.video[asset.name]);
                this.videoTexture[asset.name].flipY = true;
                this.videoTexture[asset.name].minFilter = THREE.NearestFilter;
                this.videoTexture[asset.name].magFilter = THREE.NearestFilter;
                this.videoTexture[asset.name].generateMipmaps = false;
                this.videoTexture[asset.name].encoding = THREE.sRGBEncoding;

                this.singlAssetLoaded(asset, this.videoTexture[asset.name]);
            }
        }
        this.models = models;
        this.areaLights = areaLights;
        // this.armaturePosition = armaturePosition;
    }

    singlAssetLoaded(asset: {name: string, type: string, path: string}, file: any) {
        this.items[asset.name] = file;
        this.loaded++

        if (this.loaded === this.queue) {
            this.emit("ready");
        }
    }
}