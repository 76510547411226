import expirience from "../expirience";
import * as THREE from "three";

interface meshAnimation {
    trigger__to__hover: string,
    standart__animation__name: string, 
    standart__animation__clip: any,
    standart__animation__action: any,
    hover__animation__name: string,
    hover__animation__clip: any,
    hover__animation__action: any,
    transition_to_hover__animation__name: string,
    transition_to_hover__animation__clip: any,
    transition_to_hover__animation__action: any,
    transition_to_standart__animation__name: string,
    transition_to_standart__animation__clip: any,
    transition_to_standart__animation__action: any,
    isHover: boolean,
}

interface materialAnimation {
    trigger__to__hover: string,
    standartMaterial: any,
    newMaterialName: string,
    isHover: boolean,
    mesh: any,
}



export default class animations {

    expirience: any;
    sizes: any;
    scene: any;
    canvas: any;
    camera: any;
    renderer: any;
    room: any;
    resources: any;
    enviroment: any;

    models: any;
    mixer: any;
    actualRoom: any;
    time: any;
    initialAnimations: any;
    allAnimations: (meshAnimation | materialAnimation)[];

    constructor() {


        this.expirience = new expirience();
        this.sizes = this.expirience.sizes;
        this.scene = this.expirience.scene;
        this.canvas = this.expirience.canvas;
        this.camera = this.expirience.camera;
        this.resources = this.expirience.resources;
        this.room = this.resources.items.room;
        this.actualRoom = this.room.scene;
        this.time = this.expirience.time;

        this.allAnimations = [];
        console.log(this.scene);

        // this.models = this.expirience.resources.models;

        this.mixer = new THREE.AnimationMixer(this.actualRoom);
        console.log(this.room.animations);
        this.setAnimations("Armature",);
        this.setAnimations("Monitor", "screen");
        // this.setAnimations("Guitar");
    }

    setAnimations(meshName: string, videoName?: string) {
        // console.log(this.room);
        const triggerName = "JS__" + meshName + "__Trigger";

        if (videoName) {
            let standartMaterial;
            let mesh;
            this.actualRoom.children.forEach(function(child: any) {
                if (child.name === triggerName){
                    standartMaterial = child.material;
                    mesh = child;
                }
            });
            this.allAnimations.push({
                trigger__to__hover: triggerName,
                standartMaterial: standartMaterial,
                newMaterialName: videoName,
                isHover: false,
                mesh: mesh,
            });
        }
        else {
            const clips = this.room.animations;

            const standartAnimationName = "JS__" + meshName + "__Standart";
            const transitionToHoverAnimationName = "JS__" + meshName + "__transition_to_hower";
            const transitionToStandartAnimationName = "JS__" + meshName + "__transition_to_standart";
            const hoverAnimationName = "JS__" + meshName + "__hover";

            const standartAnimation__Clip = THREE.AnimationClip.findByName(clips, standartAnimationName);
            const standartAnimation__Action = this.mixer.clipAction(standartAnimation__Clip);
            // standartAnimation__Action.fadeOut(0);
            standartAnimation__Action.clampWhenFinished = true;
            standartAnimation__Action.play();

            const transitionToHoverAnimation__Clip = THREE.AnimationClip.findByName(clips, transitionToHoverAnimationName);
            const transitionToHoverAnimation__Action = this.mixer.clipAction(transitionToHoverAnimation__Clip);
            transitionToHoverAnimation__Action.loop = THREE.LoopOnce;
            // transitionToHoverAnimation__Action.fadeOut(0);
            transitionToHoverAnimation__Action.clampWhenFinished = true;

            const hoverAnimation__Clip = THREE.AnimationClip.findByName(clips, hoverAnimationName);
            const hoverAnimation__Action = this.mixer.clipAction(hoverAnimation__Clip);
            hoverAnimation__Action.loop = THREE.LoopOnce;
            // hoverAnimation__Action.fadeOut(0);
            hoverAnimation__Action.clampWhenFinished = true;

            const transitionToStandartAnimation__Clip = THREE.AnimationClip.findByName(clips, transitionToStandartAnimationName);
            const transitionToStandartAnimation__Action = this.mixer.clipAction(transitionToStandartAnimation__Clip);
            transitionToStandartAnimation__Action.loop = THREE.LoopOnce;
            // transitionToStandartAnimation__Action.fadeOut(0);
            transitionToStandartAnimation__Action.clampWhenFinished = true;

            this.allAnimations.push({
                trigger__to__hover: triggerName,
                standart__animation__name: standartAnimationName,
                standart__animation__clip: standartAnimation__Clip,
                standart__animation__action: standartAnimation__Action,
                hover__animation__name: hoverAnimationName,
                hover__animation__clip: hoverAnimation__Clip,
                hover__animation__action: hoverAnimation__Action,
                transition_to_hover__animation__name: transitionToHoverAnimationName,
                transition_to_hover__animation__clip: transitionToHoverAnimation__Clip,
                transition_to_hover__animation__action: transitionToHoverAnimation__Action,
                transition_to_standart__animation__name: transitionToStandartAnimationName,
                transition_to_standart__animation__clip: transitionToStandartAnimation__Clip,
                transition_to_standart__animation__action: transitionToStandartAnimation__Action,
                isHover: false,
            });
            this.hoverAnimation(triggerName);
        }
    }

    switchHover(triggerToHoverName: string, isHover: boolean):void {
        if (isHover) {
            this.isHover(triggerToHoverName, isHover);
            for (let i = 0; i < this.allAnimations.length; i++) {
                if (this.allAnimations[i].trigger__to__hover === triggerToHoverName && "newMaterialName" in this.allAnimations[i]) {
                    this.changeMaterial(this.allAnimations[i] as materialAnimation);
                }
            }
        }
        else {
            this.isHover(triggerToHoverName, isHover);
            for (let i = 0; i < this.allAnimations.length; i++) {
                if (this.allAnimations[i].trigger__to__hover === triggerToHoverName && "newMaterialName" in this.allAnimations[i]) {
                    this.changeMaterial(this.allAnimations[i] as materialAnimation);
                }
            }
        }
    }

    // activateAnimation(triggerToHoverName: string): void {
    //     this.isHover(triggerToHoverName, true);
    //     for (let i = 0; i < this.allAnimations.length; i++) {
    //         if (this.allAnimations[i].trigger__to__hover === triggerToHoverName && "newMaterialName" in this.allAnimations[i]) {
    //             this.changeMaterial(this.allAnimations[i] as materialAnimation);
    //         }
    //     }
    // }

    isHover(triggerToHoverName: string, isHover: boolean) {
        // console.log(triggerToHoverName, isHover);
        if (isHover) {
            for (let i = 0; i < this.allAnimations.length; i++) {
                if (this.allAnimations[i].trigger__to__hover === triggerToHoverName) {
                    this.allAnimations[i].isHover = true;
                }
                else {
                    this.allAnimations[i].isHover = false;
                }
            }
        }
        else {
            for (let i = 0; i < this.allAnimations.length; i++) {
                if (this.allAnimations[i].trigger__to__hover === triggerToHoverName) {
                    this.allAnimations[i].isHover = false;
                }
            }
        }

    }

    changeMaterial(animation: materialAnimation): void {
        const thisResources = this.resources;
        if (animation.isHover) {
            animation.mesh.material = new THREE.MeshBasicMaterial({
                map: thisResources.items[animation.newMaterialName],
            })
        }
        else {
            animation.mesh.material = animation.standartMaterial;
        }

        // const thisResources = this.resources;
        // this.actualRoom.children.forEach(function(child: any) {
        //     if (child.name === meshName && this.allAnimations[i].isHover) {
        //         child.material = new THREE.MeshBasicMaterial({
        //             map: thisResources.items.screen,
        //         })
        //     }
        // });
    }

    hoverAnimation(triggerToHoverName: string,) {
        // console.log(triggerToHoverName, this.allAnimations);
        for (let i = 0; i < this.allAnimations.length; i++) {
            if (this.allAnimations[i].trigger__to__hover === triggerToHoverName) {
                const animationInfo = this.allAnimations[i] as meshAnimation;
                animationInfo.standart__animation__action.loop = THREE.LoopOnce;
                // animationInfo.standart__animation__action.play()


                
                    this.mixer.addEventListener("finished", function(e:any) {
                        if (animationInfo.isHover) {
                            if (e.action._clip.name === animationInfo.standart__animation__name) {
                                animationInfo.transition_to_hover__animation__action.fadeIn(1);
                                animationInfo.standart__animation__action.fadeOut(0);
                                animationInfo.transition_to_hover__animation__action.reset().play();

                                // animationInfo.transition_to_hover__animation__action.crossFadeFrom(animationInfo.standart__animation__action, 1, false);

                                // animationInfo.transition_to_hover__animation__action
                                // .reset()
                                // .setEffectiveTimeScale( 1 )
                                // .setEffectiveWeight( 1 )
                                // .fadeIn( 0.2 )
                                // .play();
                                // console.log("transition_to_hover__animation__action");
                            }
                
                            else if (e.action._clip.name === animationInfo.transition_to_hover__animation__name) {
                                animationInfo.hover__animation__action.fadeIn(1);
                                animationInfo.transition_to_hover__animation__action.fadeOut(0);
                                
                                animationInfo.hover__animation__action.reset().play();
                                // animationInfo.hover__animation__action.play();
                                // console.log("hover__animation__action");
                            }

                            else if (e.action._clip.name === animationInfo.hover__animation__name) {
                                animationInfo.hover__animation__action.fadeIn(1);
                                animationInfo.hover__animation__action.reset().play();
                                // animationInfo.hover__animation__action.play();
                                // console.log("hover__animation__action");
                            }

                            else if (e.action._clip.name === animationInfo.transition_to_standart__animation__name) {
                                // animationInfo.transition_to_standart__animation__action.clampWhenFinished = false;
                                animationInfo.transition_to_hover__animation__action.fadeIn(1);
                                animationInfo.transition_to_standart__animation__action.fadeOut(0);
                                
                                animationInfo.transition_to_hover__animation__action.reset().play();
                                // animationInfo.transition_to_hover__animation__action.play();
                                // console.log("transition_to_hover__animation__action");
                            }
                        }
                        else if (!animationInfo.isHover) {
                            if (e.action._clip.name === animationInfo.hover__animation__name) {
                                animationInfo.transition_to_standart__animation__action.clampWhenFinished = true;
                                animationInfo.transition_to_standart__animation__action.fadeIn(1);
                                animationInfo.hover__animation__action.fadeOut(0);
                                
                                animationInfo.transition_to_standart__animation__action.reset().play();
                                // console.log("transition_to_standart__animation__action");
                            }

                            else if (e.action._clip.name === animationInfo.transition_to_standart__animation__name) {
                                // animationInfo.transition_to_standart__animation__action.clampWhenFinished = false;
                                animationInfo.standart__animation__action.fadeIn(1);
                                animationInfo.transition_to_standart__animation__action.fadeOut(0);
                                
                                animationInfo.standart__animation__action.reset().play();
                                // animationInfo.standart__animation__action.play();
                                // console.log("standart__animation__action");
                            }
                
                            else if (e.action._clip.name === animationInfo.standart__animation__name) {
                                animationInfo.standart__animation__action.fadeIn(1);
                                animationInfo.standart__animation__action.reset().play();
                                // animationInfo.standart__animation__action.play();
                                // console.log("standart__animation__action");
                            }

                            else if (e.action._clip.name === animationInfo.transition_to_hover__animation__name) {
                                animationInfo.transition_to_standart__animation__action.fadeIn(1);
                                animationInfo.transition_to_hover__animation__action.fadeOut(0);
                                
                                animationInfo.transition_to_standart__animation__action.reset().play();
                                // animationInfo.transition_to_standart__animation__action.play();
                                // console.log("transition_to_standart__animation__action");
                            }
                        }
                    })

            }
        }
    }

    hoverAnimationSecond(triggerToHoverName: string) {
        console.log(triggerToHoverName);
    }

    update() {
        this.mixer.update(this.time.delta * 0.001);
    }



}