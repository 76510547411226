<template>
    <div class="navbar">
        <div class="navbar__btns">
            <my-button @click="$router.push('/')">Main Page</my-button>
            <my-button @click="$router.push('/about')">About me</my-button>
            <my-button @click="$router.push('/works')">My Works</my-button>
            <my-button @click="$router.push('/skills')">My Skills</my-button>
            <div class="navbar__lang">
                <lang-menu></lang-menu>
            </div>
        </div>
        <div class="openCloseButton">
            <div class="openCloseButton-line"></div>
            <div class="openCloseButton-line"></div>
            <div class="openCloseButton-line"></div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from "vue";

    export default defineComponent ({
        name: "nav-bar",
        data() {
            return {

            }
        },
        methods: {
            openCloseMenu(): void {
                const openCloseButton = document.querySelector(".openCloseButton");
                const navbar = document.querySelector(".navbar");
                openCloseButton?.addEventListener("click", () => {
                    openCloseButton.classList.toggle("openCloseButton-active");
                    navbar?.classList.toggle("navbar-active");
                });
            }
        },
        mounted() {
            this.openCloseMenu();
        }
    })
</script>

<style lang="scss" scoped>
    .navbar {
        position: fixed;
        top: 0px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 20px 0;
        z-index: 2;
    }

    .navbar__btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        background-color: rgba(50, 50, 50, 0.75);
        border-radius: 50px;
        padding: 10px;
        z-index: 1;
        border: 1px solid #000;
    }

    .navbar__lang {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .openCloseButton {
        display: none;
    }


    @media (max-width: 650px) {

        .navbar {
            padding: 0;
            height: 100%;
            top: -125vh;
            transition: top 0.3s ease-in-out;
        }

        .navbar-active {
            top: 0;
        }

        .navbar__btns {
            flex-direction: column;
            border-radius: 0;
            width: 100%;
            height: 100%;

            .btn {
                padding: 15px 30px;
            }

            .lang__button {
                width: 116px;
            }
        }

        .openCloseButton {
            display: flex;
            flex-direction: column;
            gap: 6px;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 30px;
            right: 30px;
            width: 30px;
            height: 30px;
            z-index: 2;

            .openCloseButton-line {
                position: relative;
                width: 30px;
                height: 3px;
                border-radius: 50px;
                background: #fff;
                transition: all 0.3s ease-in-out;
            }
        }

        .openCloseButton-active {
            .openCloseButton-line {
                &:nth-child(1) {
                    transform: rotate(405deg);
                    top: 4px;
                }

                &:nth-child(2) {
                    display: none;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                    top: -4px;
                }
            }
        }
        
    }

</style>