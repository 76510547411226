import mainPage from "@/pages/MainPage.vue"
import AboutMe from "@/pages/AboutMe.vue"
import skills from "@/pages/MySkills.vue"
import works from "@/pages/MyWorks.vue"
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main',
    component: mainPage
  },
  {
    path: '/about',
    name: 'about',
    component: AboutMe
  },
  {
    path: '/skills',
    name: 'skills',
    component: skills
  },
  {
    path: '/works',
    name: 'works',
    component: works
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
