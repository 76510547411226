import { MutationTree } from "vuex";
import { State, Lang} from "./state";


//   mutations: {
//     setLang__active(state:any, lang:string) {
//       state.lang__active.forEach(function (element:any) {
//         if (element.language === lang) {
//           element.isActive = true;
//         }
//         else {
//           element.isActive = false;
//         }
//       });
//     }
//   },

export enum MutationType {
    setLanguages = "setLanguages",
}

export type Mutations = {
    [MutationType.setLanguages](state: State, lang: string): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationType.setLanguages](state: State, lang: string) {
        state.languages.forEach(function (element:Lang) {
            if (element.language === lang) {
              element.isActive = true;
            }
            else {
              element.isActive = false;
            }
       });
    }
}