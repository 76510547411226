import expirience from "./expirience";
import * as THREE from "three";

export default class renderer {

    expirience: any;
    sizes: any;
    scene: any;
    canvas: any;
    camera: any;
    renderer: any;

    constructor() {

        this.expirience = new expirience();
        this.sizes = this.expirience.sizes;
        this.scene = this.expirience.scene;
        this.canvas = this.expirience.canvas;
        this.camera = this.expirience.camera;

        this.setRenderer();
    }

    setRenderer() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
        })

        // this.renderer.physicallyCorrectLights = true;
        this.renderer.useLegacyLights = false;
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.toneMapping = THREE.CineonToneMapping;
        this.renderer.toneMappingExposure = 1.75;
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(this.sizes.pixelRatio);
    }

    resize() {
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(this.sizes.pixelRatio);
    }

    update() {
        this.renderer.render(this.scene, this.camera.activeCamera)
    }
}