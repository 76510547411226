// export type Langs = {
//     [lang__active: number]: {language: string, isActive: boolean},
// }

// export const State = ({
//   state: () => ({
//     lang__active: (): Langs => [
//       {
//           language: "Русский",
//           isActive: true,
//       },
//       {
//           language: "English",
//           isActive: false,
//       }
//     ],
//   }),
// })

export type Lang = {
  language: string,
  isActive: boolean,
  name: string,
}

export type State = {
  lang__active: string,
  languages: Lang[],
}

export const state: State = ({
  lang__active: "",
  languages:
  [
    {
      language: "Русский",
      isActive: true,
      name: "ru",
    },
    {
        language: "English",
        isActive: false,
        name: "en",
    },
  ],
  
})