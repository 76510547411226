<template>
  <div class="info">
    <div class="info__performance">
      <img class="info__photo" src="foto-me.jpg" alt="">
      <img class="info__photo displayNone" src="ritm.webp" alt="">
      <img class="info__photo displayNone" src="foto-dance-test.jpg" alt="">
      <!-- <canvas></canvas> -->
      <div class="info__imageWrapper"></div>
    </div>
    <div class="info__description">
      <h1 class="info__h1">{{ $t("aboutMe.h1") }}</h1>
      <div class="info__main-text">
        <p>{{ $t("aboutMe.hello") }}<span class="info__imageTrigger" >{{ $t("aboutMe.hello__name") }}</span>{{ $t("aboutMe.hello__2") }}</p>
        <p>{{ $t("aboutMe.about") }}<span>{{ $t("aboutMe.city") }}</span>{{ $t("aboutMe.about__2") }}</p>
        <p>{{ $t("aboutMe.freeTime") }}<span class="info__imageTrigger">{{ $t("aboutMe.freeTime__guitar") }}</span>{{ $t("aboutMe.freeTime__2") }}<span class="info__imageTrigger" >{{ $t("aboutMe.freeTime__dance") }}</span>{{ $t("aboutMe.Hobbies") }}</p>
        <p>{{ $t("aboutMe.thankU") }}</p>
        <p>{{ $t("aboutMe.contacts") }}</p>
      </div>

    


      <ul class="info__social social">
        <li class="social__icon">
          <a class="social__class" href="">
            <img class="social__img" src="discord-logo.png" alt="">
          </a>
        </li>
        <li class="social__icon">
          <a class="social__class" href="">
            <img class="social__img" src="HeadHunter_logo.png" alt="">
          </a>
        </li>
        <li class="social__icon">
          <a class="social__class" href="">
            <img class="social__img" src="VK-logo.png" alt="">
          </a>
        </li>
      </ul>


    </div>

  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from "vue";
  import { useStore } from '@/store'

  export default defineComponent ({

   name: "AboutMe-Page",

   data() {
     return {
      // language: this.lang__active(),
     }
   },
   methods: {
    changePicture() {
      const triggers = document.querySelectorAll(".info__imageTrigger");
      const images = document.querySelectorAll(".info__photo");
      const imageWrapper = document.querySelector(".info__imageWrapper");
      let whatPictureIsActive = 0;
      let isActive = false;

      for (let i = 0; i < triggers.length; i++) {
        triggers[i].addEventListener("mouseover", () => {
          if (!isActive && whatPictureIsActive != i) {
            isActive = !isActive;
            imageWrapper?.classList.add("info__imageWrapper-shadow");
            setTimeout(() => {
              for (let j = 0; j < images.length; j++) {
                if (i === j) {
                  images[j].classList.remove("displayNone");
                  whatPictureIsActive = j;
                }
                else {
                  images[j].classList.add("displayNone");
                }
              }
              imageWrapper?.classList.remove("info__imageWrapper-shadow");
              isActive = !isActive;
            }, 300)
          }
        })
      }
    },
   },
   computed: {
    lang__active() {
      return useStore().state.lang__active;
    },
   },
   watch: {
    language() {
      this.changePicture();
    }
   },
   mounted() {
    this.changePicture();
   },

  })
</script>

<style scoped lang="scss">

  .displayNone {
    display: none;
  }

  .info {
    overflow: hidden;
  }

  p {
    margin: 10px 0;
  }
  .info {
    position: relative;
    display: flex;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.95) 25%, rgba(225, 225, 225, 0) 35%);
    padding-left: 30px;
    padding-top: 60px;
    max-height: 100vh;
    height: 100vh;
    font-family: 'Schibsted_Grotesk', sans-serif;

  }


  .info__description {
    color: #fff;
    max-width: 25%;
    overflow: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    padding-bottom: 10px;
  }

  .info__h1 {
    text-transform: uppercase;
    margin: 20px 0;
    font-size: 85px;
  }

  .info__main-text span {
    color: rgb(129, 245, 119);
  }

  .info__performance {
    position: absolute;
    display: flex;
    justify-content: start;
    top: 0;
    right: 0;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    z-index: -1;
    background: rgb(25, 25, 25);
  }

  .info__photo {
    width: 100%;
    max-height: 100%;

    &:nth-child(2) {
      transform: scale(-1, 1);
    }
  }

  .info__imageWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
  }

  .info__imageWrapper-shadow {
    background: #000;
  }

  .social {
    display: flex;
    align-items: center;
    gap: 10px;
    list-style: none;
  }

  .social__img{
    max-width: 20px;
    max-height: 20px;
  }
  

  @media (max-width: 1440px) {

    .info {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.95) 45%, rgba(225, 225, 225, 0) 60%);
  
    }

    .info__description {
      max-width: 45%;
    }

    .info__performance {
      right: -30%;
    }
  }

  @media (max-width: 650px) {

    .info {
      background: rgba(0, 0, 0, 0.7);
      padding-right: 30px;
      padding-top: 30px;
    }

    .info__performance {
      right: 0;
    }

    .info__description {
      max-width: 100%;
    }

    .info__h1 {
      margin: 0;
    }
  }

  @media (max-width: 540px) {
    .info__h1 {
      font-size: 45px;
      text-align: center;
    }
    
  }

  @media (max-width: 400px) {
    .info {
      padding-top: 60px;
    }
  }

</style>
