import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59db2400"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main__block sites-block" }
const _hoisted_2 = { class: "sites-block__header" }
const _hoisted_3 = { class: "sites-block__description-wrapper" }
const _hoisted_4 = { class: "sites-block__description" }
const _hoisted_5 = { class: "sites-block__href-wrapper" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "sites-block__img-wrapper" }
const _hoisted_9 = { class: "swiper-wrapper" }
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.content.name)), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.content.description)), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.hrefs, (href) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: href.link
        }, [
          (href.isArchive)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "sites-block__href webArchive",
                href: href.link
              }, _toDisplayString(_ctx.$t('myworks.web__archive')), 9, _hoisted_6))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "sites-block__href webArchive",
                href: href.link
              }, _toDisplayString(href.link), 9, _hoisted_7))
        ], 64))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.content.imgs.length > 1)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.content.id + '__swiperJS', "swiperJS sites-block__slider slider"])
            }, [
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.imgs, (img) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: img.img_src,
                    class: "swiper-slide slider__slide"
                  }, [
                    _createElementVNode("img", {
                      class: "sites-block__img",
                      src: require(`@/assets/img/works/${img.img_src}`),
                      alt: img.img_alt
                    }, null, 8, _hoisted_10)
                  ]))
                }), 128))
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.content.id + '__paginationJS', "swiper-pagination"])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.content.id + '__swiper-button-prevJS', "swiper-button-prev"])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.content.id + '__swiper-button-nextJS', "swiper-button-next"])
            }, null, 2)
          ], 64))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "sites-block__img",
            src: require(`@/assets/img/works/${_ctx.content.imgs[0].img_src}`),
            alt: _ctx.content.imgs[0].img_alt
          }, null, 8, _hoisted_11))
    ])
  ]))
}