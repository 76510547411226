import * as THREE from "three";
import sizes from "@/components/ts/expirience/utils/sizes";
import camera from "./camera";
import renderer from "./renderer";
import time from "@/components/ts/expirience/utils/time"
import world from "./world/world"
import resources from "./utils/resources";
import assets from "./utils/assets";

export default class expirience {

    static instance: any;
    static isExist: boolean;
    canvas: any;
    scene: any;
    sizes: any;
    camera: any;
    renderer: any;
    time: any;
    world: any;
    resources: any;

    raycasterHoverBlocker: boolean;
    isListenerAdded: boolean;
    lastHoverMeshName: string;
    

    constructor(canvas?: any,) {
        
        if (expirience.instance) {
            expirience.isExist = true;
            return expirience.instance;
        }

        expirience.instance = this;
        expirience.isExist = false;
        this.canvas = canvas;
        this.scene = new THREE.Scene();
        this.addBackgroundToScene();
        this.time = new time();
        this.sizes = new sizes();
        this.resources = new resources(assets);
        this.camera = new camera();
        this.renderer = new renderer();
        

        this.raycasterHoverBlocker = false;
        this.isListenerAdded = false;
        this.lastHoverMeshName = "";
  
        
        this.world = new world();

        

        this.time.on("update", () => {
            this.update();
        })
        this.sizes.on("resize", () => {
            this.resize();
        })
        
        this.addRaycaster();
        // for (let i = 0; i < this.scene.children.length; i++) {
        //     console.log(this.scene.children[i]);
        // }
        // console.log(this.resources);
    }

    addBackgroundToScene() {
        const loader = new THREE.CubeTextureLoader();
        loader.setPath( '/texture/skybox/' );
        const textureCube = loader.load( [
            'posx.jpg', 'negx.jpg',
            'posy.jpg', 'negy.jpg',
            'posz.jpg', 'negz.jpg'
        ] );
        this.scene.background = textureCube;
    }

    addRaycaster() {
        const pointer = new THREE.Vector2();
        const raycaster = new THREE.Raycaster();

        const onMouseMove = (event: any) => {
            pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
            pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

            raycaster.setFromCamera(pointer, this.camera.activeCamera);

            const interects = raycaster.intersectObjects(this.scene.children, true);
            const hover: any = document.querySelector(".hover");

            const onMouseClick = () => {
                if (this.raycasterHoverBlocker) {
                    const expirienceBlock: any = document.querySelector(".expirience");
                    console.log("onMouseClick");
                    window.removeEventListener("click", onMouseClick);
                    expirienceBlock?.dispatchEvent(new CustomEvent("blockClick", {
                        detail: {
                            trigger: interects[0].object.name,
                        }
                    }));
                }

            }

            const createHoverEvent = (object: string, isHover: boolean) => {
                if (isHover) {
                    hover?.dispatchEvent(new CustomEvent("hover", {
                        detail: { 
                            isHover: true,
                            trigger: object,
                        }
                    }));
                }
                else {
                    hover?.dispatchEvent(new CustomEvent("hover", {
                        detail: { 
                            isHover: false,
                            trigger: object,
                        }
                    }));
                }

            }

            for ( let i = 0; i < interects.length; i++) {
                // console.log(interects[0].object.name);
                if (interects[0].object.name != this.lastHoverMeshName) {
                    for (let i = 0; i < this.world.room.animations.allAnimations.length; i++) {
                        if (interects[0].object.name === this.world.room.animations.allAnimations[i].trigger__to__hover) {
                            this.world.room.animations.switchHover(interects[0].object.name, true);
                            createHoverEvent(interects[0].object.name, true);
                            if (!this.isListenerAdded) {
                                this.isListenerAdded = true;
                                window.addEventListener("click", onMouseClick);
                            }
                        }
                        else if (this.lastHoverMeshName === this.world.room.animations.allAnimations[i].trigger__to__hover) {
                            this.world.room.animations.switchHover(this.lastHoverMeshName, false);
                            createHoverEvent(this.lastHoverMeshName, false);
                            if (this.isListenerAdded) {
                                this.isListenerAdded = false;
                                window.removeEventListener("click", onMouseClick);
                            }
                        }
                    }
                    this.lastHoverMeshName = interects[0].object.name;
                }





                // if (interects[0].object.name.includes("JS__") && interects[0].object.name.includes("__Trigger")) {
                //     // if (interects[0].object.name.includes("Video")) {
                //     //     this.world.room.animations.changeMaterial(interects[0].object.name);
                //     //     console.log(interects[0].object);
                //     // }
                //     if (!this.raycasterHoverBlocker) {
                //         this.raycasterHoverBlocker = true;
                //         this.world.room.animations.activateAnimation(interects[0].object.name);
                //         createHoverEvent(interects[0].object.name, true);
                //         if (!this.isListenerAdded) {
                //             this.isListenerAdded = true;
                //             window.addEventListener("click", onMouseClick);
                //         }
                //     }
                // }
                // else {
                //     this.raycasterHoverBlocker = false;
                //     this.world.room.animations.activateAnimation(interects[0].object.name);
                //     createHoverEvent(interects[0].object.name, false);
                //     if (this.isListenerAdded) {
                //         this.isListenerAdded = false;
                //         window.removeEventListener("click", onMouseClick);
                //     }
                // }
            }
        }

        window.addEventListener("mousemove", onMouseMove);
    }

    update() {
        // console.log(this.camera.perspectiveCamera.position);
        this.renderer.update();
        this.world.update();
    }

    resize() {
        this.renderer.resize();
        // this.world.resize();
    }

}