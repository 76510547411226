<template>
    <div class="works">

        <div class="h1__wrapper">
            <h1 class="h1">{{ $t("myworks.h1") }}</h1>
        </div>


        <div class="main">

            <work-block
                v-for="contentBlock in content"
                :key="contentBlock.name"
                v-bind:content="contentBlock"
                >
            </work-block>

        </div>

    </div>
</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";

  export default defineComponent ({
    name: "myWorks-Page",

    data() {
     return {
        content: 
        [
            {
                id: "Aveoceo",
                name: "myworks.aveoceo__header",
                description: "myworks.aveoceo__description",
                hrefs:
                [
                    {
                        link: "https://web.archive.org/web/20211211055551/https://aveoceo.com/",
                        isArchive: true
                    },
                ],
                imgs: 
                [
                    {
                        img_src: "Aveoceo1.png",
                        img_alt: "Aveoceo",
                    },
                ]
            },
            {
                id: "AnnetNoFat",
                name: "myworks.AnnetNoFat__header",
                description: "myworks.AnnetNoFat__description",
                hrefs:
                [
                    {
                        link: "https://web.archive.org/web/20220327005506/https://annetnofat.ru/",
                        isArchive: true
                    },
                ],
                imgs: 
                [
                    {
                        img_src: "AnnetNoFat.png",
                        img_alt: "AnnetNoFat",
                    },
                ]
            },
            {
                id: "Mostbet",
                name: "myworks.Mostbet__header",
                description: "myworks.Mostbet__description",
                hrefs:
                [
                    {
                        link: "https://most-bet-bonus.com/",
                        isArchive: false
                    },
                    {
                        link: "https://mostbetofficial.co.in/",
                        isArchive: false
                    },
                    {
                        link: "https://most-bet.in/",
                        isArchive: false
                    },
                    {
                        link: "https://most-bet.co.in/",
                        isArchive: false
                    },
                    {
                        link: "https://mostbetofficial.in/",
                        isArchive: false
                    },
                ],
                imgs: 
                [
                    {
                        img_src: "most-bet-bonus.png",
                        img_alt: "most-bet-bonus",
                    },
                    {
                        img_src: "most-bet-co-in.png",
                        img_alt: "most-bet-co-in",
                    },
                    {
                        img_src: "most-bet-in.png",
                        img_alt: "most-bet-in",
                    },
                    {
                        img_src: "mostbetofficial.png",
                        img_alt: "mostbetofficial",
                    },
                    {
                        img_src: "mostbetofficial-in.png",
                        img_alt: "mostbetofficial-in",
                    },
                    {
                        img_src: "mostbetofficial-kz.png",
                        img_alt: "mostbetofficial-kz",
                    },
                    {
                        img_src: "most-bet-co-in-mirror.png",
                        img_alt: "most-bet-co-in-mirror",
                    },
                    {
                        img_src: "mostbetofficial-2.png",
                        img_alt: "mostbetofficial-2",
                    },
                    {
                        img_src: "mostbetofficial-in-registration.png",
                        img_alt: "mostbetofficial-in-registration",
                    },
                    {
                        img_src: "most-bet-in-casino.png",
                        img_alt: "most-bet-in-casino",
                    },
                ]
            },
            {
                id: "PinUp",
                name: "myworks.PinUp__header",
                description: "myworks.PinUp__description",
                hrefs:
                [
                    {
                        link: "https://pinupslot.kz",
                        isArchive: false
                    },
                    {
                        link: "https://pin-up-casino.kiev.ua/",
                        isArchive: false
                    },
                    {
                        link: "https://pinupslot.kz/",
                        isArchive: false
                    },
                    {
                        link: "https://pinup-bet.kz/",
                        isArchive: false
                    },
                ],
                imgs: 
                [
                    {
                        img_src: "casinopinup-kz.png",
                        img_alt: "casinopinup-kz",
                    },
                    {
                        img_src: "pinup-bet-kz.png",
                        img_alt: "pinup-bet-kz",
                    },
                    {
                        img_src: "pinup-bet-kz-bonus.png",
                        img_alt: "pinup-bet-kz-bonus",
                    },
                    {
                        img_src: "pinup-bet-kz-promo.png",
                        img_alt: "pinup-bet-kz-promo",
                    },
                    {
                        img_src: "pin-up-casino-kiev-ua.png",
                        img_alt: "pin-up-casino-kiev-ua",
                    },
                    {
                        img_src: "pin-up-casino-kiev-ua-articles.png",
                        img_alt: "pin-up-casino-kiev-ua-articles",
                    },
                    {
                        img_src: "pin-up-casino-kiev-ua-promo.png",
                        img_alt: "pin-up-casino-kiev-ua-promo",
                    },
                    {
                        img_src: "pinupslot.kz-articles.png",
                        img_alt: "pinupslot.kz-articles",
                    },
                    {
                        img_src: "pinupslot.kz-promo.png",
                        img_alt: "pinupslot.kz-promo",
                    },
                    {
                        img_src: "pinupslot-kz.png",
                        img_alt: "pinupslot-kz",
                    },
                ]
            },
            {
                id: "RiDrive",
                name: "myworks.RiDrive__header",
                description: "myworks.RiDrive__description",
                hrefs:
                [
                    {
                        link: "https://ridrive.ae/",
                        isArchive: false
                    },
                ],
                imgs: 
                [
                    {
                        img_src: "ridrive-ae.png",
                        img_alt: "RiDrive",
                    },
                ]
            },
        ]
     }
   },
  })
</script>

<style scoped lang="scss">
    @import "@/scss/pages/myWorks.scss";
</style>


<style lang="scss">
</style>