<template>
    <div class="lang">
        <my-button class="lang__active"> {{languages[0].language}} </my-button>
        <div class="lang__menu">
            <my-button v-for="availableLocale in availableLocales" v-bind:key="availableLocale" @click="changeLocale(availableLocale)" class="lang__button">{{availableLocale}}</my-button>
        </div>
    </div>
</template>










<script lang="ts">
    import { useStore } from '@/store'
    import { useI18n } from 'vue-i18n';
    import { defineComponent } from "vue";
    // import { Locales } from '@/i18n/locales';
    


    export default defineComponent ({

        setup() {
            const { t, locale, availableLocales } = useI18n({useScope: "global"});

            const changeLocale = (newLocale: string) => {
                locale.value = newLocale;
            };


            return {
                changeLocale,
                availableLocales
            };

        },
        name: "lang-menu",



        data() {
            return {
                languages: useStore().state.languages,
            }
        },
        methods: {
            addListeners() {
                this.showLangMenu();
                this.hideLangMenu();
            },
            showLangMenu() {
                const mainButton = document.querySelector(".lang__active");
                const changeLangButton = document.querySelectorAll<HTMLElement>(".lang__button");
                mainButton?.addEventListener("mouseover", () => {
                    for (let i = 0; i < changeLangButton.length; i++) {
                        const mainButtonHeight = mainButton.clientHeight ;
                        const changeLangButtonHeight = changeLangButton[0].clientHeight;
                        const borderWIdth = parseInt(getComputedStyle(changeLangButton[0], null).getPropertyValue("border-width"));
                        changeLangButton[i].style.top = mainButtonHeight + (2 * borderWIdth) + i * (changeLangButtonHeight + borderWIdth) + "px";
                    }
                });
            },
            hideLangMenu() {
                
                const navBar = document.querySelector(".navbar");
                const changeLangButton = document.querySelectorAll<HTMLElement>(".lang__button");
                    navBar?.addEventListener("mouseleave", (event) => {
                        const target = event.target;
                        if (target != navBar) {
                            return 0;
                        }
                        else {
                            for (let i = 0; i < changeLangButton.length; i++) {
                                changeLangButton[i].style.top = 0 + "px";
                            }
                        }

                    }, true);
            },

            // changeLang() {
            //     this.clickLangButton();
            // },
            // clickLangButton() {
            //     const changeLangButton = document.querySelectorAll<HTMLElement>(".lang__button");
            //     for (let i = 0; i < changeLangButton.length; i++) {
            //         changeLangButton[i].addEventListener("click", () => {
            //             for (let j = 0; j < this.languages.length; j++) {
            //                 if (j === i) {
            //                     this.languages[j].isActive = true;
            //                     localStorage.language = this.languages[j].name;
            //                     useStore().state.lang__active = this.languages[j].name;
            //                 }
            //                 else {
            //                     this.languages[j].isActive = false;
            //                 }
                            
            //             }
            //         });
            //     }
            // },
            // detectLnguage() {
            //     if (!localStorage.language) {
            //         if (window.navigator.language.toLowerCase().includes("ru")) {
            //             localStorage.language = "ru";
            //         }
            //         else {
            //             localStorage.language = "en";
            //         }
            //     }
            //     else {
            //         useStore().state.lang__active = localStorage.language;
            //         for (let i = 0; i < this.languages.length; i++) {
            //             if (this.languages[i].name === useStore().state.lang__active) {
            //                 this.languages[i].isActive = true;
            //             }
            //             else {
            //                 this.languages[i].isActive = false;
            //             }
            //         }
            //     }
            // },
            
        },



        
        mounted() {
            const store = useStore();
            this.languages = useStore().state.languages;
            this.addListeners();
            // this.changeLang();
            // this.detectLnguage();
        },
        watch: {
            lang__active(newValue) {
                this.$nextTick().then(() => { this.addListeners();})
            },
            
        },
        computed: {
        }
    })
</script>









<style scoped lang="scss">
    .lang {
        position: relative;
        display: flex;
        justify-content: start;
        font-family: "Rubik";
        color: rgb(230, 230, 230);
    }
    .lang__menu {
        position: relative;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: start;
        z-index: -1;
        max-width: 100%;
        min-width: 100%;
        right: 100%;
    }

    .lang__button {
        position: absolute;
        width: 100%;
        top: 0;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 650px) {
        .btn  {
            padding: 15px 30px;
        }
    }


</style>