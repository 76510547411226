<template>
  <nav-bar></nav-bar>
  <router-view></router-view>
  
</template>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
</style>
