export default [
    {
        name: "room",
        type: "glbModel",
        path: "/models/workplace5_Lights.glb"
    },
    {
        name: "screen",
        type: "videoTexture",
        path: "/texture/Matrix Raining Code.mp4"
    }
]