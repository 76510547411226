<template>
    <div class="skills">
        <div class="h1__wrapper">
            <h1 class="h1">{{$t("mySkills.h1")}}</h1>
        </div>
        
        <div class="skills__menu">
            <skills-block
            v-for="contentBlock in content"
            :key="contentBlock.block_name"
            v-bind:content="contentBlock"
            >
            </skills-block>
        </div>

    </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from "vue";

  export default defineComponent ({
    name: "mySkills-Page",

    data() {
     return {
        content:[
            {
                block_name: "Frontend",
                skills: [
                    {
                        name: "C++",
                        level: [100, 0, 0, 0, 0]
                    },
                    {
                        name: "JS",
                        level: [100, 50, 0, 0, 0]
                    },
                    {
                        name: "HTML",
                        level: [100, 100, 100, 0, 0]
                    },
                    {
                        name: "CSS",
                        level: [100, 100, 100, 0, 0]
                    },
                    {
                        name: "MJML",
                        level: [100, 100, 100, 0, 0]
                    },
                    {
                        name: "Three.js",
                        level: [100, 0, 0, 0, 0]
                    },
                    {
                        name: "PHP",
                        level: [100, 0, 0, 0, 0]
                    },
                ],
            },
            {
                block_name: "Backend",
                skills: [
                    {
                        name: "PHP",
                        level: [100, 0, 0, 0, 0]
                    },
                    {
                        name: "SQL",
                        level: [100, 0, 0, 0, 0]
                    },
                ],
            },
            {
                block_name: "3D",
                skills: [
                    {
                        name: "Blender",
                        level: [100, 0, 0, 0, 0]
                    },
                ],
            },
            {
                block_name: "Other",
                skills: [
                    {
                        name: "C++",
                        level: [100, 0, 0, 0, 0]
                    },
                    {
                        name: "SEO",
                        level: [100, 100, 100, 0, 0]
                    },
                    {
                        name: "Figma",
                        level: [100, 100, 100, 0, 0]
                    },
                    {
                        name: "Photoshop",
                        level: [100, 75, 0, 0, 0]
                    },
                ],
            },
        ]
     }
   },
  })
</script>

<style scoped lang="scss">
    
    @import "@/scss/pages/mySkills.scss"

</style>