<template>
  <div class="home">
    <div class="expirience">
      <canvas ref="canvas" class="expirience__canvas">

      </canvas>
      <div class="hover">
        <h2 class="hover__name">
          {{$t("mainPage.hoverName__" + hoverName)}}
        </h2>
        <p class="hover__description">
          {{$t("mainPage.hoverDescription__" + hoverName)}}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  import expirience from "@/components/ts/expirience/expirience";
  import router from "@/router";

  export default defineComponent ({
    name: "Main-Page",
    data() {
      return {
        componentKey: 0,
        hoverName: "Armature",
        routerBlocks: [
          {
            path: '/about',
            blockname: "Armature",
          },
          {
            path: '/skills',
            blockname: "guitar",
          },
          {
            path: '/works',
            blockname: "monitor",
          }
        ],
      }
    },
    methods: {
      startThree(): void {
        const expirienceCanvas = new expirience(this.$refs.canvas);
        if (expirience.isExist) {
          expirienceCanvas.renderer.canvas = this.$refs.canvas;
          expirienceCanvas.renderer.setRenderer()
        }
      },
      hoverClassFollowsCursor(): void {
        const hover: any = document.querySelector(".hover");
        const expirience: any = document.querySelector(".expirience");
        expirience?.addEventListener('mousemove', function (event: any) {
          const x = event.clientX;
          const y = event.clientY; 
          if (hover != undefined) {
            hover.style.top = event.clientY - 25 + "px";
            hover.style.left = event.clientX + 25 + "px";
          }
        });
      },
      hoverClassVisibility(): void {
        const hover: any = document.querySelector(".hover");

        const changeHoverContent = (newContent: string): void => {
          this.hoverName = newContent;
        }

        hover.addEventListener("hover", (event: any) => {
          if (event.detail?.isHover) {
            hover.style.visibility = "visible";
            changeHoverContent(event.detail.trigger.split('JS__')[1].split('__Trigger')[0]);
          }
          else {
            hover.style.visibility = "hidden";
          }
        });
      },
      routePage(): void {
        const expirience: any = document.querySelector(".expirience"); 
        expirience.addEventListener("blockClick", (event: any) => {
          console.log("blockClick");
          if (event.detail?.trigger) {
            console.log(event.detail?.trigger)
            const trigger = event.detail.trigger.split('JS__')[1].split('__Trigger')[0];
            for (let i = 0; i < this.routerBlocks.length; i++) {
              if (this.routerBlocks[i].blockname.toLowerCase() === trigger.toLowerCase()) {
                router.push({ path: this.routerBlocks[i].path})
              }
            }
          }
        }) 
      },
    },
    mounted() {
      this.startThree();
      this.hoverClassFollowsCursor();
      this.hoverClassVisibility();
      this.routePage();
    },
  })

</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import "@/scss/pages/mainPage.scss"

</style>
