<template>
    <div class="skills__menu-block">
        <h2 class="skills__header">{{content.block_name}}</h2>
        <div class="skills__menu-option-wrapper">
            <template v-for="skills in content.skills" :key="skills.name">
                <div class="skills__menu-option">
                    <p class="skills__menu-name">{{skills.name}}</p>
                    <div class="skills__menu-rating-wrapper">
                        <div 
                            v-for="(level, index) in skills.level" 
                            :key="index" 
                            :class="
                                [{'filled100': skills.level[index] == 100},
                                {'filled75': skills.level[index] == 75},
                                {'filled50': skills.level[index] == 50},
                                {'filled25': skills.level[index] == 25},]" 
                            class="skills__menu-rating">
                        </div>
                    </div>
                </div>
            </template>
        </div>

    </div>
</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";
    import type { PropType } from 'vue';

    interface MyProps {
        block_name: string,
        skills: [],
    }
    
    export default defineComponent({
        name: "skills-block",
        data() {
            return {
            }
        },
        props: {
            content: {
                type: Object as PropType<MyProps>,
                required: true,
            }
        },
    })
</script>

<style scoped lang="scss">
    @import "@/scss/contentBlocks/skills.scss"

</style>