import expirience from "../expirience";
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import * as THREE from "three";

export default class enviroment {

    expirience: any;
    sizes: any;
    scene: any;
    renderer: any;
    resources: any
    sunLight: any;
    pointLight: any;
    ambientLight: any;

    constructor() {

        this.expirience = new expirience();
        this.scene = this.expirience.scene;
        this.resources = this.expirience.resources;

        // this.setSunlight();
        // this.setAmbientLight();
        // this.setPointLight();
        // this.setRectAreaLight();
        // this.Replacethelight();
    }

    setSunlight() {
        this.sunLight = new THREE.DirectionalLight("#ffffff", 3);
        this.sunLight.castShadow = true;
        this.sunLight.shadow.camera.far = 20;
        this.sunLight.shadow.mapSize.set(1024, 1024);
        this.sunLight.shadow.normalBias = 0.05;
        this.sunLight.position.set(1.5, 7, 3);
        this.scene.add(this.sunLight);
    }

    setPointLight() {
        this.pointLight = new THREE.PointLight( "#ffffff", 0.1, 100 );
        this.pointLight.position.set( -2, 5, 0 );
        this.pointLight.castShadow = true;
        this.scene.add( this.pointLight );

    }

    setAmbientLight() {
        this.ambientLight = new THREE.AmbientLight("#ffffff", 0.1);
        this.scene.add(this.ambientLight);
    }

    setRectAreaLight() {
        // const width = 10;
        // const height = 10;
        // const intensity = 1;
        // const rectLight = new THREE.RectAreaLight( 0xffffff, intensity,  width, height );
        // rectLight.position.set( 5, 5, 0 );
        // rectLight.lookAt( 0, 0, 0 );
        // scene.add( rectLight )
        // console.log("hi");

        for (let i = 0; i < this.resources.areaLights.length; i++) {
            const width = 10;
            const height = 10;
            const intensity = 1;
            const rectLight = new THREE.RectAreaLight( 0xffffff, intensity,  width, height );
            rectLight.position.set( this.resources.areaLights[i].position.x, this.resources.areaLights[i].position.y, this.resources.areaLights[i].position.z + 10);
            // rectLight.lookAt( 0, 0, 0 );
            // rectLight.rotation( 0);


            const helper = new RectAreaLightHelper( rectLight );
            rectLight.add( helper ); // helper must be added as a child of the light
            
            this.scene.add( rectLight )
        }
    }

    getArmaturePosition() {
        this.resources.models;
        for (let i = 0; i < this.resources.models.length; i++) {
            if (this.resources.models[i].name.includes("Armature")) {
                return this.resources.models[i].position;
            }
        }
    }

    Replacethelight() {
        for (let i = 0; i < this.resources.models.length; i++) {
            if (this.resources.models[i].name.includes("ThreeJS")) {
                console.log(this.resources.models[i]);
                if (this.resources.models[i].name.includes("Point") && (this.resources.models[i].isLight)) {
                    this.pointLight = new THREE.PointLight( 
                        "#ffffff", 
                        this.resources.models[i].intensity, 
                        this.resources.models[i].distance );
                    this.pointLight.position.set( 
                        this.resources.models[i-1].position.x,
                        this.resources.models[i-1].position.y,
                        this.resources.models[i-1].position.z
                     );
                    this.pointLight.castShadow = false;
                    this.scene.add( this.pointLight );
                }
                this.removeObject(this.resources.models[i])
            }
        }
    }

    removeObject(obj: any) {
        if (obj.children.length > 0) {
            for (let i = 0; i < obj.children.length; i++) {
                this.removeObject(obj.children[i]);
            }
        }
        if (obj.isMesh) {
            obj.geometry.dispose();
            obj.material.dispose();
        }
        if (obj.parent) {
            obj.parent.remove(obj);
        }
    }


}